import axios from 'axios'
import {getToken} from './auth.js'

const service = axios.create({
    baseURL: 'https://api.imunet.xyz:12000/blog/',  // https://api.imunet.xyz:12000/blog/  // https://pub.imunet.xyz:11123/blog/
    timeout: 5000
})

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

service.interceptors.request.use(
    config => {
        // before sent
        if (getToken()) {
            config.headers['Token'] = getToken();
            // config.headers.Authorization = getToken();
        }
        return config;
    },
    error => {
        console.log(error);
        return Promise.reject(error);
    }
)

service.interceptors.response.use(
    response => {
        // 拦截
        const res = response.data;
        return res;
    },
    error => {
        console.log(error);
        return Promise.reject(error);
    }
)

export default service