<template>
    <div id="app" class="app">
        <router-view />
    </div>
</template>

<script>
Date.prototype.Format = function (fmt) {
    //author: meizz
    var o = {
        "M+": this.getMonth() + 1, //月份
        "d+": this.getDate(), //日
        "h+": this.getHours(), //小时
        "m+": this.getMinutes(), //分
        "s+": this.getSeconds(), //秒
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度
        S: this.getMilliseconds(), //毫秒
    };
    if (/(y+)/.test(fmt))
        fmt = fmt.replace(
            RegExp.$1,
            (this.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
    for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt))
            fmt = fmt.replace(
                RegExp.$1,
                RegExp.$1.length == 1
                    ? o[k]
                    : ("00" + o[k]).substr(("" + o[k]).length)
            );
    return fmt;
};
</script>

<style>
html,
body {
    height: 100%;
    background: #f4f4f4;
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
        "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
}

.my-title {
    font-size: 16px;
    font-weight: bold;
    color: #121212;
}

.my-font-lg {
    font-size: 16px;
    line-height: 1.5rem;
    color: #121212;
}

.my-font-md {
    font-size: 14px;
    line-height: 1.3rem;
    color: #121212;
}

.my-font-sm {
    font-size: 12px;
}

.my-font-bold {
    font-weight: bold;
}

.my-flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.my-flex-col {
    display: flex;
    flex-direction: column;
}

.my-card {
    padding: 1rem;

    height: auto;
    width: calc(100% - 2rem);

    background-color: #fff;
}

.my-card-border {
    padding: 1rem;

    height: auto;

    background-color: #fff;

    box-sizing: border-box;
    border: 1px solid #ebedf0;
}
</style>
