import Vue from "vue";
import VueRouter from "vue-router";
import { getColumnById } from "@/api/common"

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Index",
        redirect: "/home",
        component: () => import("@/views/index/index"),
        children: [
            {
                path: "/home",
                name: "Home",
                component: () => import("@/views/home/index"),
                meta: {
                    title: "首页",
                },
            },
            {
                path: "/peoplelist",
                name: "PeopleList",
                component: () => import("@/views/peopleList/index"),
                meta: {
                    title: "团队成员",
                },
            },
            {
                path: "/wordslist/:kind",
                name: "WordsList",
                component: () => import("@/views/wordsList/index"),
            },
            {
                path: "/wordspage/:id",
                name: "WordsPage",
                component: () => import("@/views/wordsPage/index"),
                meta: {
                    title: "文章",
                },
            },
            {
                path: "/personpage/:id",
                name: "PersonPage",
                component: () => import("@/views/personPage/index"),
                meta: {
                    title: "成员信息",
                },
            },
            {
                path: "/login",
                name: "Login",
                component: () => import("@/views/login/index"),
                meta: {
                    title: "登录",
                },
            },
            {
                path: "/introduction",
                name: "Introduction",
                component: () => import("@/views/introduction/index"),
                meta: {
                    title: "小组介绍",
                },
            },
        ],
    },
];

const router = new VueRouter({
    routes,
});

router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title + " - 内蒙古大学网络组";
    } else if (to.params.kind  !== undefined) {
        const data = {
            columnId : to.params.kind
        };
        getColumnById(data).then(res => {
            if(res.code === 20000) {
                document.title = res.data.columnTitle + " - 内蒙古大学网络组";
            }
        })
    } else {
		document.title = "内蒙古大学网络组";
	}
    next();
});

export default router;
