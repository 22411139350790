import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// Vant
import {
    Tabbar,
    TabbarItem,
    Popup,
    NavBar,
    Icon,
    Collapse,
    CollapseItem,
    Cell,
    CellGroup,
    Swipe,
    SwipeItem,
    Image,
    Divider,
    Tab,
    Tabs,
    Field,
    Button,
    Search,
    Pagination,
    Toast,
} from "vant";

Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Popup);
Vue.use(NavBar);
Vue.use(Icon);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Image);
Vue.use(Divider);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Field);
Vue.use(Button);
Vue.use(Search);
Vue.use(Pagination);
Vue.use(Toast);

Vue.config.productionTip = false;

Toast.setDefaultOptions({ duration: 1000 });

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
