import request from '@/utils/request';

export function getBlogList(data) {
    // 获取博客
    return request({
        url: '/api/common/bloglist',
        method: 'get',
        params: data
    })
}

export function getBlogById(data) {
    // 使用 id 获取 blog 详情
    return request({
        url: '/api/common/getblogbyid',
        method: 'get',
        params: data
    })
}

export function getCommentById(data) {
    // 获取评论
    return request({
        url: 'api/common/getcomments',
        method: 'get',
        params: data
    })
}

export function getColumnById(data) {
    // 按 ID 获取分类
    return request({
        url: 'api/common/getcolumnbyid',
        method: 'get',
        params: data
    })
}

export function getUserList() {
    // 获取全部学生
    return request({
        url: 'api/common/studentlist',
        method: 'get'
    })
}

export function getUserById(data) {
    // 使用 id 获取成员信息
    return request({
        url: '/api/common/getuserbyid',
        method: 'get',
        params: data
    })
}

export function getColumnListAsc() {
    // 获取栏目列表
    return request({
        url: '/api/common/columnlistasc',
        method: 'get',
    })
}

export function login(data) {
    // 用户登录
    return request({
        url: '/api/common/login',
        method: 'post',
        data
    })
}

export function getSlide() {
    // 获取轮播图
    return request({
        url: "/api/common/getslides",
        method: "get",
    });
}

export function getHomePage() {
    // 获取首页最新动态
    return request({
        url: "/api/common/beginpage",
        method: "get",
    });
}

export function getProfessor() {
    // 获取导师信息
    return request({
        url: 'api/common/professor',
        method: 'get'
    })
}

export function getPage() {
    return request({
        url: "/api/common/getpage",
        method: "get",
    });
}